
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    assessment: Object as PropType<any>,
    stage: String as PropType<string>,
    available: Boolean as PropType<boolean>,
  },
  computed: {
    isOutcomeSelectionStage() {
      return [
        'Outcome-selection venture',
        'Outcome-selection reviewer',
      ].includes(this.stage)
    },
    outcomes() {
      if (!this.available) return []
      const outcomeStage = this.assessment.outcomesByStages.find(
        (e: any) => e.stage === this.stage,
      )
      return outcomeStage
        ? outcomeStage.outcomes.map(
            (id: string) => this.assessment.outcomes[id],
          )
        : []
    },
  },
})
